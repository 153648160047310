import { Entity } from '@backstage/catalog-model';
import React from 'react';
import { Alert, Skeleton } from '@material-ui/lab';
import { Card, makeStyles } from '@material-ui/core';
import { ProductVersionsListItem } from './ProductVersionsListItem';
import { useDelayedLoading } from '../../../../../../../hooks/useDelayedLoading';
import { useProductVersions } from '../useProductVersions';

type ProductVersionsListProps = {
  entity: Entity;
};

const useProductVersionsSkeletonItemStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

export const ProductVersionsItemSkeleton = () => {
  const classes = useProductVersionsSkeletonItemStyles();

  return (
    <Card className={classes.root}>
      <Skeleton variant="text" width={100} />
      <Skeleton variant="text" width={150} height={50} />
      <Skeleton variant="rect" height={140} />
    </Card>
  );
};

const useProductVersionsListStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export const ProductVersionsList = (props: ProductVersionsListProps) => {
  const { entity } = props;
  const classes = useProductVersionsListStyles();
  const { entities, loading, error } = useProductVersions(entity);

  const delayedLoading = useDelayedLoading(loading);

  if (delayedLoading) {
    return (
      <div className={classes.root}>
        <ProductVersionsItemSkeleton />
        <ProductVersionsItemSkeleton />
      </div>
    );
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <div className={classes.root}>
      {entities?.map(productVersion => (
        <ProductVersionsListItem
          key={productVersion.metadata.uid}
          entity={productVersion}
        />
      ))}
    </div>
  );
};

ProductVersionsList.displayName = 'ProductVersionsList';
