import GitHubIcon from '@material-ui/icons/GitHub';
import { HeaderSkeleton } from '../ProductRefEntitySkeletons';
import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';
import { GITHUB_REPO_SLUG_ANNOTATION } from 'custom-annotations';
import { Alert } from '@material-ui/lab';
import { isEntityInitializedOrBeyond } from '../../../../../../../../utils/lifecycleUtils';

const useProductReleaseCardHeaderStyles = makeStyles(theme => ({
  root: { display: 'flex', padding: '1rem' },
  githubIcon: {
    margin: 'auto',
  },
  contentContainer: {
    flex: 1,
    marginRight: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
  },
  overline: {
    fontSize: theme.spacing(1.4),
  },
  title: {
    display: 'flex',
    fontSize: theme.spacing(3),
  },
  githubOrg: {
    color: theme.palette.text.secondary,
  },
  repoName: {
    fontWeight: 'bold',
  },
  slugError: {
    width: `calc(100% - ${theme.spacing(3.5)}px)`,
    marginTop: theme.spacing(1),
  },
  orgRepoNameSeparator: {
    margin: `0 ${theme.spacing(0.5)}px`,
  },
}));

export const ProductReleaseCardHeader = () => {
  const classes = useProductReleaseCardHeaderStyles();
  const { entity } = useEntity();
  const initialized = isEntityInitializedOrBeyond(entity);
  const githubSlug =
    entity?.metadata.annotations?.[GITHUB_REPO_SLUG_ANNOTATION]?.split('/');

  return (
    <div className={classes.root}>
      <GitHubIcon fontSize="large" className={classes.githubIcon} />
      <div className={classes.contentContainer}>
        {initialized ? (
          <>
            <Typography variant="overline" className={classes.overline}>
              Location - GITHUB
            </Typography>

            <div className={classes.title}>
              {githubSlug ? (
                <>
                  <span className={classes.githubOrg}>{githubSlug[0]}</span>
                  <span className={classes.orgRepoNameSeparator}>/</span>
                  <span className={classes.repoName}>{githubSlug[1]}</span>
                </>
              ) : (
                <Alert severity="error" className={classes.slugError}>
                  Failed to find github slug in annotations
                </Alert>
              )}
            </div>
          </>
        ) : (
          <HeaderSkeleton />
        )}
      </div>
    </div>
  );
};
