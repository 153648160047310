import { Avatar, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { GroupEntity, RELATION_OWNED_BY } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { SubscriptionWithVersion } from '../../../../../../hooks/useProductSubscriptions';

const useDeploymentAccordionOwnerStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  ownerName: {
    fontWeight: 700,
    marginLeft: theme.spacing(0.5),
  },
  avatar: {
    marginLeft: theme.spacing(2),
  },
}));

interface DeploymentAccordionOwnerProps {
  subscription: SubscriptionWithVersion;
}

export const DeploymentAccordionOwner = ({
  subscription,
}: DeploymentAccordionOwnerProps) => {
  const classes = useDeploymentAccordionOwnerStyles();

  const [owner, setOwner] = useState<GroupEntity | undefined>();
  const catalogApi = useApi(catalogApiRef);

  const ownedByRelation = useMemo(
    () => getEntityRelations(subscription, RELATION_OWNED_BY),
    [subscription],
  );

  useEffect(() => {
    catalogApi.getEntityByRef(ownedByRelation[0]).then(entity => {
      setOwner(entity as GroupEntity);
    });
  }, [catalogApi, ownedByRelation]);

  const ownerName = (owner as GroupEntity)?.spec?.profile?.displayName;

  return (
    <div className={classes.root}>
      <span className={classes.secondaryText}>
        <span>Deployed by</span>
        <span className={classes.ownerName}>{ownerName}</span>
      </span>
      <Avatar className={classes.avatar} src={owner?.spec.profile?.picture}>
        {ownerName?.[0] ?? ''}
      </Avatar>
    </div>
  );
};
