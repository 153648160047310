import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  Breadcrumbs,
  BreadcrumbsItems,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { HomeIcon, Inventory2Icon } from 'backstage-plugin-icons-react';

const PackageEntityBreadcrumb = () => {
  const { entity } = useEntity();

  return (
    <Breadcrumbs>
      <BreadcrumbsItems label="Home" to="/" Icon={HomeIcon} isCompact />
      <BreadcrumbsItems
        label={entity.metadata.title ?? ''}
        Icon={Inventory2Icon}
        isLast
      />
    </Breadcrumbs>
  );
};

PackageEntityBreadcrumb.displayName = 'PackageEntityBreadcrumb';

export { PackageEntityBreadcrumb };
