import { CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import {
  EntityRefLink,
  EntityRefLinks,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import {
  Entity,
  RELATION_DEPENDS_ON,
  RELATION_OWNED_BY,
} from '@backstage/catalog-model';
import { AboutField } from '@backstage/plugin-catalog';
import { FullHeightCard } from '../../../../../utils/FullHeightCard';

interface AboutSubscriptionProps {
  entity: Entity;
}

export const AboutSubscription = ({ entity }: AboutSubscriptionProps) => {
  const { entities: ownedByRelations } = useRelatedEntities(entity, {
    kind: 'group',
    type: RELATION_OWNED_BY,
  });

  const { entities: dependsOnRelations } = useRelatedEntities(entity, {
    kind: 'component',
    type: RELATION_DEPENDS_ON,
  });

  if (dependsOnRelations && dependsOnRelations.length > 1) {
    throw new Error(
      'Cannot guess package if subscription depends on more than one entity',
    );
  }

  const packageEntity = dependsOnRelations?.[0];

  return (
    <FullHeightCard>
      <CardHeader title="About" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <AboutField label="Owner" value="No owner" gridSizes={{ sm: 4 }}>
            {ownedByRelations && ownedByRelations?.length > 0 && (
              <EntityRefLinks entityRefs={ownedByRelations} />
            )}
          </AboutField>
          <AboutField label="Package" value="No package" gridSizes={{ sm: 4 }}>
            {packageEntity && <EntityRefLink entityRef={packageEntity} />}
          </AboutField>
        </Grid>
      </CardContent>
    </FullHeightCard>
  );
};
