import React, { forwardRef, useMemo } from 'react';
import { EntityPresentationContext } from './EntityPresentationContext';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Props for {@link EntityPresentation}.
 *
 * @public
 */
export type EntityPresentationProps = {
  size?: 'small' | 'medium';
} & React.HTMLAttributes<HTMLDivElement>;

const useEntityPresentationStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}));

/**
 * Displays an entity presentation.
 *
 * @remarks
 *
 * This component provides a context for entity presentation, allowing child components to adjust
 * their presentation based on the provided `size` prop.
 *
 * @public
 */
export const EntityPresentation = forwardRef<
  HTMLDivElement,
  EntityPresentationProps
>((props, ref) => {
  const { size = 'medium', children, ...restProps } = props;

  const value = useMemo(() => ({ size }), [size]);

  const classes = useEntityPresentationStyles();

  return (
    <EntityPresentationContext.Provider value={value}>
      <div className={classes.content} ref={ref} {...restProps}>
        {children}
      </div>
    </EntityPresentationContext.Provider>
  );
});

EntityPresentation.displayName = 'EntityPresentation';
