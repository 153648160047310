import { SubscriptionWithVersion } from '../../../../../../hooks/useProductSubscriptions';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import {
  Entity,
  RELATION_DEPENDS_ON,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Table,
  IconText,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { getEntityIcon } from '../../../../../../../Root/sidebar/recently-visited/utils';
import { DeploymentAccordionStatus } from '../AccordionHeader/DeploymentAccordionStatus';
import { makeStyles } from '@material-ui/core';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ARGO_APP_NAME_ANNOTATION } from 'custom-annotations';
import { DeploymentStatus } from '../useDeploymentStatus';

const entitiesTypeBlacklist = [
  'product',
  'productVersion',
  'package',
  'packageVersion',
];

const useDeploymentAccordionStyles = makeStyles(theme => ({
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.3)}px !important`,
  },
}));

interface DeploymentAccordionContentProps {
  subscriptionWithVersion: SubscriptionWithVersion;
  statuses?: Record<string, DeploymentStatus>;
}

export const DeploymentAccordionContent = ({
  subscriptionWithVersion,
  statuses,
}: DeploymentAccordionContentProps) => {
  const classes = useDeploymentAccordionStyles();
  const subscriptionDependsOnRelationRefs = useMemo(
    () => getEntityRelations(subscriptionWithVersion, RELATION_DEPENDS_ON),
    [subscriptionWithVersion],
  );
  const [componentsAndResources, setComponentsAndResources] = useState<
    Entity[]
  >([]);

  const catalogApi = useApi(catalogApiRef);

  useEffect(() => {
    const getComponentsAndResource = async () => {
      const { items } = await catalogApi.getEntitiesByRefs({
        entityRefs: subscriptionDependsOnRelationRefs.map(stringifyEntityRef),
        filter: { kind: ['Component', 'Resource'] },
      });

      setComponentsAndResources(
        items.filter(
          (item): item is Entity =>
            Boolean(item) &&
            !entitiesTypeBlacklist.includes(item?.spec?.type as string),
        ),
      );
    };

    getComponentsAndResource();
  }, [subscriptionDependsOnRelationRefs, catalogApi]);

  const getCellClassName = useCallback(() => classes.tableCell, [classes]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'kind',
        headerName: 'Kind',
        flex: 0.4,
        renderCell: (params: GridRenderCellParams) => (
          <IconText Icon={getEntityIcon(params.row)} text={params.value} />
        ),
      },
      { field: 'title', headerName: 'Name', flex: 0.5 },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.5,
        renderCell: params => {
          const status =
            statuses?.[
              params.row.entity.metadata.annotations[ARGO_APP_NAME_ANNOTATION]
            ];
          return <DeploymentAccordionStatus status={status} />;
        },
      },
      { field: 'description', headerName: 'Description', flex: 1 },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 0.2,
        type: 'actions',
        getActions: () => [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="See details"
            onClick={() => {}}
          />,
          <GridActionsCellItem
            icon={<MoreHorizIcon />}
            label="Toggle Admin"
            onClick={() => {}}
          />,
        ],
      },
    ],
    [statuses],
  );

  const rows = useMemo(
    () =>
      componentsAndResources?.map(entity => ({
        id: entity.metadata.uid,
        kind: entity.kind,
        title: entity.metadata.title,
        status: 'In Progress',
        description: entity.metadata.description,
        entity,
      })) ?? [],
    [componentsAndResources],
  );

  return (
    <Table
      columns={columns}
      rows={rows}
      rowHeight={70}
      getCellClassName={getCellClassName}
      disableColumnSorting
      disableColumnResize
      disableColumnMenu
      disableRowSelectionOnClick
    />
  );
};
