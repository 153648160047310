import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { classNames } from '../../../../../../../utils/classNames';

const useSkeletonsStyles = makeStyles(theme => ({
  light: {
    display: 'inline-block',
  },
  dark: {
    display: 'inline-block',
    backgroundColor: 'rgba(38, 70, 83, .3)',
  },
  contentFirstSkeleton: {
    marginRight: '1rem',
  },
  headerThirdSkeleton: {
    marginRight: '.5rem',
  },
  headerFirstSkeleton: {
    display: 'block',
  },
  headerSkeletonSeparator: {
    margin: `0 ${theme.spacing(0.5)}px`,
  },
}));

export const ContentSkeleton = () => {
  const classes = useSkeletonsStyles();
  const skeletons = [];
  for (let i = 0; i < 4; i++) {
    skeletons.push(
      <React.Fragment key={i}>
        <Skeleton
          className={classNames(classes.dark, classes.contentFirstSkeleton)}
          variant="text"
          width={75}
          animation={false}
        />
        <Skeleton
          className={classes.light}
          variant="text"
          width={75}
          animation={false}
        />
        <br />
      </React.Fragment>,
    );
  }

  return <>{skeletons}</>;
};

export const HeaderSkeleton = () => {
  const classes = useSkeletonsStyles();

  return (
    <>
      <Skeleton
        variant="text"
        width={125}
        className={classes.headerFirstSkeleton}
        animation={false}
      />
      <Skeleton
        className={classes.light}
        variant="text"
        width={75}
        animation={false}
      />
      <span className={classes.headerSkeletonSeparator}>/</span>
      <Skeleton
        className={classNames(classes.dark, classes.headerThirdSkeleton)}
        variant="text"
        width={200}
        animation={false}
      />
      <Skeleton
        className={classes.dark}
        variant="text"
        width={75}
        animation={false}
      />
    </>
  );
};
