import React, { forwardRef } from 'react';
import {
  Breadcrumbs as MuiBreadcrumbs,
  type BreadcrumbsProps as MuiBreadcrumbsProps,
} from '@material-ui/core';

export type BreadcrumbsProps = MuiBreadcrumbsProps;

export const Breadcrumbs = forwardRef<HTMLDivElement, BreadcrumbsProps>(
  (props, ref) => {
    const { children, ...rest } = props;
    return (
      <MuiBreadcrumbs ref={ref} {...rest}>
        {children}
      </MuiBreadcrumbs>
    );
  },
);

Breadcrumbs.displayName = 'Breadcrumbs';
