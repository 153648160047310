import { useEffect, useState } from 'react';

export const useDelayedLoading = (
  loading: boolean,
  delayedLoadingTime: number = 200,
) => {
  const [delayedLoading, setDelayedLoading] = useState<boolean>(loading);

  useEffect(() => {
    if (loading) {
      setTimeout(() => setDelayedLoading(false), delayedLoadingTime);
    }
  }, [loading, delayedLoadingTime]);

  return delayedLoading;
};
