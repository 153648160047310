import React, { forwardRef, useContext, useMemo } from 'react';
import { IconComponent } from '@backstage/core-plugin-api';
import { Link } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { EntityPresentationContext } from '../EntityPresentationContext';

/**
 * Metrics type for {@link EntityPresentationMetricsItem}.
 *
 * @public
 */
export type Metric = {
  label: string;
  value: string;
  Icon?: IconComponent;
  link?: string | null;
};

/**
 * Props for {@link EntityPresentationMetricsItem}.
 *
 * @public
 */

export type EntityPresentationMetricsItemProps = Metric &
  React.HTMLAttributes<HTMLDivElement>;

const useEntityPresentationMetricsItemStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  contentValue: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  labelSmall: {
    fontSize: '10px',
  },
  labelMedium: {
    fontSize: '12px',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
}));

/**
 * Displays a metric for an entity presentation.
 *
 * @remarks
 *
 * This component displays a metric with a label and a value. this component can also display an
 * icon. If a link is provided, the value will be wrapped in a {@link Link}. This component is
 * intended to be used within an {@link EntityPresentationMetrics}.
 *
 * @public
 */
export const EntityPresentationMetricsItem = forwardRef<
  HTMLDivElement,
  EntityPresentationMetricsItemProps
>((props, ref) => {
  const { label, value, Icon, link, ...restProps } = props;
  const { size } = useContext(EntityPresentationContext);
  const classes = useEntityPresentationMetricsItemStyles();

  const labelClasses = useMemo(() => {
    return `${classes.label} ${size === 'small' ? classes.labelSmall : classes.labelMedium}`;
  }, [classes, size]);

  const contentValue = (
    <div className={classes.contentValue}>
      {Icon && (
        <div className={classes.icon}>
          <Icon fontSize="inherit" />
        </div>
      )}
      <Typography variant={size === 'small' ? 'body2' : 'body1'}>
        {value}
      </Typography>
    </div>
  );

  return (
    <div ref={ref} className={classes.root} {...restProps}>
      <Typography className={labelClasses}>{label}</Typography>
      {link ? <Link to={link}>{contentValue}</Link> : contentValue}
    </div>
  );
});

EntityPresentationMetricsItem.displayName = 'EntityPresentationMetricsItem';
