import {
  Checkbox,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React, { useCallback } from 'react';
import { useDeploymentsStore } from '../deployments-store';

const useProjectsFilterStyle = makeStyles(theme => ({
  formControl: {
    flex: 1,
  },
  select: {
    background: theme.palette.common.white,
  },
  selectEmptyText: {
    fontStyle: 'italic',
  },
}));

export const ProjectsFilter = () => {
  const classes = useProjectsFilterStyle();

  const projects = useDeploymentsStore(state => state.projects);
  const projectFilter = useDeploymentsStore(state => state.projectFilter);
  const setProjectFilter = useDeploymentsStore(state => state.setProjectFilter);

  const renderProjectsValue = useCallback(
    (selectedProjects: unknown) => {
      if ((selectedProjects as string[]).length === 0)
        return (
          <span className={classes.selectEmptyText}>No project selected</span>
        );

      return (selectedProjects as string[]).length === projects.length
        ? 'All projects'
        : (selectedProjects as string[]).join(', ');
    },
    [projects.length, classes.selectEmptyText],
  );

  const onProjectsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProjectFilter(event.target.value as string[]);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        labelId="project-label"
        id="project-select"
        value={projectFilter}
        multiple
        displayEmpty
        renderValue={renderProjectsValue}
        onChange={onProjectsChange}
        className={classes.select}
        MenuProps={{
          getContentAnchorEl: null,
        }}
      >
        {projects.map(name => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={projectFilter.includes(name)} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
