import { Entity } from '@backstage/catalog-model';
import {
  DatabaseIcon,
  DeployedCodeIcon,
  Inventory2Icon,
  MemoryIcon,
  Package2Icon,
  PersonIcon,
  GroupIcon,
} from 'backstage-plugin-icons-react';
import { JsonObject } from '@backstage/types';

export const getEntityDisplayName = (entity: Entity) => {
  switch (entity.kind) {
    case 'Component':
    case 'API':
    case 'Resource':
      return entity.metadata.title ?? entity.metadata.name;
    case 'Group':
    case 'User':
      return (
        ((entity.spec?.profile as JsonObject)?.displayName as string) ??
        entity.metadata.name
      );
    default:
      return entity.metadata.name;
  }
};

export const getEntityOverline = (entity: Entity) => {
  switch (entity.kind) {
    case 'Component':
    case 'API':
    case 'Resource':
      return `${entity.spec?.type as string}`;
    case 'Group':
      return `${entity.kind} - ${entity.spec?.type as string}`;
    case 'User':
      return `${entity.kind} - ${entity.metadata.name}`;
    default:
      return `${entity.kind} - ${entity.spec?.type as string}`;
  }
};

export const getEntityIcon = (entity: Entity) => {
  switch (entity.kind) {
    case 'Component':
      switch (entity.spec?.type) {
        case 'package':
          return Inventory2Icon;
        case 'product':
          return Package2Icon;
        default:
          return DeployedCodeIcon;
      }
    case 'Resource':
      return DatabaseIcon;
    case 'Group':
      return GroupIcon;
    case 'User':
      return PersonIcon;
    default:
      return MemoryIcon;
  }
};
