import { Chip, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { DeployedVersion } from './useDeployedVersions';
import { classNames } from '../../../../../../../../../utils/classNames';

const useVersionItemsStyles = makeStyles(theme => ({
  versionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(5),
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  deployedVersionText: {
    color: theme.palette.text.disabled,
  },
  deployedVersionChip: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  availableVersionChip: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
  },
  chip: {
    marginBottom: 0,
  },
  scaffolderLink: {
    color: 'inherit',
  },
}));

export const DeployedVersionItem = ({
  version,
}: {
  version: DeployedVersion;
}) => {
  const classes = useVersionItemsStyles();

  return (
    <li className={classes.versionRow}>
      <Typography variant="body2" className={classes.deployedVersionText}>
        {version.name}
      </Typography>
      <Chip
        variant="outlined"
        size="small"
        className={classNames(classes.deployedVersionChip, classes.chip)}
        label={
          <Typography variant="subtitle2">
            deployed in <b>{version.deployments[0]}</b>
          </Typography>
        }
      />
    </li>
  );
};

export const AvailableVersionItem = ({
  version,
}: {
  version: DeployedVersion;
}) => {
  const classes = useVersionItemsStyles();

  const scaffolderTemplateRoute = useRouteRef(
    scaffolderPlugin.routes.selectedTemplate,
  );

  const subscriptionRoute = `${scaffolderTemplateRoute({
    namespace: 'default',
    templateName: 'product-subscription-template',
  })}?formData={"productVersion":"${version.entityRef}"}`;

  return (
    <li className={classes.versionRow}>
      <Link to={subscriptionRoute} className={classes.scaffolderLink}>
        <Typography variant="body2">{version.name}</Typography>
      </Link>
      <div>
        {!!version.deployments.length && (
          <Chip
            variant="outlined"
            size="small"
            className={classNames(classes.chip, classes.availableVersionChip)}
            label={
              <Typography variant="subtitle2">
                deployed in <b>{version.deployments[0]}</b>
              </Typography>
            }
          />
        )}
        {version.deployments.length > 1 && (
          <Chip
            variant="outlined"
            size="small"
            label={`+${version.deployments.length - 1}`}
            className={classes.chip}
          />
        )}
      </div>
    </li>
  );
};
