import { EntityLayout } from '@backstage/plugin-catalog';
import { OverviewContent } from '../../content/OverviewContent';
import { TechDocsContent } from '../../content/TechDocsContent';
import { RelationsContent } from '../../content/RelationsContent';
import React from 'react';
import { DbApiPage } from 'backstage-plugin-dbapi';

export const DatabasePage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {OverviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {TechDocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/relations" title="Relations">
      {RelationsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/dbapi" title="DBAPI">
      <DbApiPage />
    </EntityLayout.Route>
  </EntityLayout>
);
