import React from 'react';
import { type Entity } from '@backstage/catalog-model';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEntityRoute } from '../../hooks/useEntityRoute';
import {
  EntityPresentation,
  EntityPresentationDescription,
  EntityPresentationHeader,
  EntityPresentationMetrics,
  ButtonCard,
} from '@internal/backstage-plugin-adeo-core-components-react';

import { useMetrics } from '../../hooks/useMetrics';

const usePackageDiscoveryCardStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  errorContainer: {
    margin: theme.spacing(2),
  },
}));

export const PackageDiscoveryCard = ({
  packageEntity,
}: {
  packageEntity: Entity;
}) => {
  const classes = usePackageDiscoveryCardStyles();
  const packageRoute = useEntityRoute(packageEntity);

  const { loading, metrics, error } = useMetrics(packageEntity, [
    'owner',
    'namespace',
    'products',
  ]);

  return (
    <Grid item key={packageEntity.metadata.uid} xs={12} lg={6}>
      <ButtonCard to={packageRoute}>
        <EntityPresentation size="small">
          <div className={classes.content}>
            <EntityPresentationHeader
              title={packageEntity.metadata.title ?? 'No title'}
              type={(packageEntity.spec?.type as string) ?? ''}
            />
            <EntityPresentationDescription
              description={
                packageEntity.metadata.description ?? 'No description'
              }
            />
          </div>
          <Divider style={{ marginTop: 'auto' }} />
          {error ? (
            <Alert severity="error" className={classes.errorContainer}>
              {error.message}
            </Alert>
          ) : (
            <EntityPresentationMetrics metrics={metrics} loading={loading} />
          )}
        </EntityPresentation>
      </ButtonCard>
    </Grid>
  );
};
