import {
  RELATION_OWNED_BY,
  RELATION_DEPENDS_ON,
  RELATION_DEPENDENCY_OF,
  type Entity,
  type GroupEntity,
} from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';
import {
  useRelatedEntities,
  getEntityRelations,
  entityRouteRef,
} from '@backstage/plugin-catalog-react';
import Group from '@material-ui/icons/Group';
import OpenInNew from '@material-ui/icons/OpenInNew';
import { type Metric } from '@internal/backstage-plugin-adeo-core-components-react';
import {
  DatabaseIcon,
  DeployedCodeIcon,
  Inventory2Icon,
  Package2Icon,
} from 'backstage-plugin-icons-react';

import { createMetrics } from '../utils/createMetrics';
import { useDelayedLoading } from './useDelayedLoading';

/**
 * Delayed loading time in milliseconds
 */

export type MetricsKey = keyof typeof getMetricsMap;

type UseMetricsType = (
  entity: Entity,
  metricsKeysToDisplay?: MetricsKey[],
) => {
  loading: boolean;
  metrics: Metric[];
  error?: Error;
};

type MetricsMapFunction = (
  entity: Entity,
  relatedEntities: Entity[],
  getEntityRoute: (entity: Entity) => string | null,
) => {
  metrics: Metric[];
  error?: Error;
};

type GenerateEntityRoute = ({
  kind,
  namespace,
  name,
}: {
  kind: string;
  namespace: string;
  name: string;
}) => string;

const getEntityRouteFactory =
  (generateEntityRoute: GenerateEntityRoute) => (entity: Entity) => {
    if (!entity.metadata?.namespace) {
      return null;
    }

    return generateEntityRoute({
      kind: entity.kind,
      namespace: entity.metadata.namespace,
      name: entity.metadata.name,
    });
  };

const getOwnerMetrics: MetricsMapFunction = (
  entity,
  relatedEntities,
  getEntityRoute,
) => {
  const ownerReferences = getEntityRelations(entity, RELATION_OWNED_BY);
  if (ownerReferences.length === 0)
    return {
      error: new Error(`No owner for the entity ${entity.metadata.name}`),
      metrics: [],
    };

  const owner = relatedEntities.find(
    relatedEntity => relatedEntity.metadata.name === ownerReferences[0].name,
  ) as GroupEntity;

  if (!owner)
    return {
      error: new Error(
        `No owner entity found for the entity ${entity.metadata.name}`,
      ),
      metrics: [],
    };

  if (!owner.spec?.profile?.displayName)
    return {
      error: new Error(
        `No owner name found for the entity ${owner.metadata.name}`,
      ),
      metrics: [],
    };

  const metric = createMetrics(
    owner.spec?.profile?.displayName,
    'Owner',
    Group,
    getEntityRoute(owner),
  );

  return { metrics: [metric] };
};

const getNamespaceMetrics: MetricsMapFunction = (
  entity,
  _relatedEntities,
  _getEntityRoute,
) => {
  if (!entity.metadata.namespace)
    return {
      error: new Error(
        `No namespace found for the entity ${entity.metadata.name}`,
      ),
      metrics: [],
    };
  const metric = createMetrics(entity.metadata.namespace, 'Namespace');

  return { metrics: [metric] };
};

const getPackageMetrics: MetricsMapFunction = (
  entity,
  relatedEntities,
  getEntityRoute,
) => {
  if (entity.spec?.type !== 'product')
    return {
      error: new Error('You cannot display a package for a non-product entity'),
      metrics: [],
    };
  const packagesReferences = getEntityRelations(
    entity,
    RELATION_DEPENDENCY_OF,
    {
      kind: 'component',
    },
  );
  if (packagesReferences.length === 0)
    return {
      error: new Error(
        `No package found for the entity ${entity.metadata.name}`,
      ),
      metrics: [],
    };
  const packageParent = relatedEntities.find(
    relatedEntity => packagesReferences[0].name === relatedEntity.metadata.name,
  );
  if (!packageParent)
    return {
      error: new Error(
        `No package entity found for the entity ${entity.metadata.name}`,
      ),
      metrics: [],
    };

  const metric = createMetrics(
    packageParent.metadata?.name,
    'Package',
    Inventory2Icon,
    getEntityRoute(packageParent),
  );
  return { metrics: [metric] };
};

const getParentProductMetrics: MetricsMapFunction = (
  entity,
  relatedEntities,
  getEntityRoute,
) => {
  if (entity.spec?.type !== 'productVersion')
    return {
      error: new Error(
        'You cannot display a package for a non-product-version entity',
      ),
      metrics: [],
    };
  const dependencieReferencies = getEntityRelations(
    entity,
    RELATION_DEPENDENCY_OF,
    {
      kind: 'component',
    },
  );

  if (dependencieReferencies.length === 0)
    return {
      error: new Error(
        `No product found for the entity ${entity.metadata.name}`,
      ),
      metrics: [],
    };

  const dependencies = relatedEntities.filter(relatedEntity =>
    dependencieReferencies.some(
      dependency => dependency.name === relatedEntity.metadata.name,
    ),
  );

  const productParent = dependencies?.find(
    dependency => dependency.spec?.type === 'product',
  );

  if (!productParent)
    return {
      error: new Error(
        `No product entity found for the entity ${entity.metadata.name}`,
      ),
      metrics: [],
    };

  const metric = createMetrics(
    productParent.metadata?.name,
    'Product',
    Package2Icon,
    getEntityRoute(productParent),
  );
  return { metrics: [metric] };
};

const getProductsMetrics: MetricsMapFunction = (
  entity,
  relatedEntities,
  _getEntityRoute,
) => {
  if (entity.spec?.type !== 'package')
    return {
      error: new Error('You cannot display products for a non-package entity'),
      metrics: [],
    };
  const productsReferences = getEntityRelations(entity, RELATION_DEPENDS_ON);
  if (productsReferences.length === 0)
    return {
      error: new Error(
        `No products found for the entity ${entity.metadata.name}`,
      ),
      metrics: [],
    };
  const products = relatedEntities.filter(relatedEntity =>
    productsReferences.some(
      productReference => productReference.name === relatedEntity.metadata.name,
    ),
  );
  if (products.length !== productsReferences.length)
    return {
      error: new Error(
        `At least one product entity not found for the entity ${entity.metadata.name}`,
      ),
      metrics: [],
    };

  const metric = createMetrics(products.length, 'Products', Package2Icon);
  return { metrics: [metric] };
};

const getComponentsMetrics: MetricsMapFunction = (
  entity,
  _relatedEntities,
  _getEntityRoute,
) => {
  if (entity.spec?.type !== 'product' && entity.spec?.type !== 'productVersion')
    return {
      error: new Error(
        'You cannot display components for a non-product or a non-product-version entity',
      ),
      metrics: [],
    };

  const componentsReferences = getEntityRelations(entity, RELATION_DEPENDS_ON, {
    kind: 'component',
  });
  if (componentsReferences.length === 0)
    return {
      error: new Error(
        `No components found for the entity ${entity.metadata.name}`,
      ),
      metrics: [],
    };
  const metric = createMetrics(
    componentsReferences.length,
    'Components',
    DeployedCodeIcon,
  );

  return { metrics: [metric] };
};

const getResourcesMetrics: MetricsMapFunction = (
  entity,
  _relatedEntities,
  _getEntityRoute,
) => {
  if (entity.spec?.type !== 'product' && entity.spec?.type !== 'productVersion')
    return {
      error: new Error(
        'You cannot display resources for a non-product or a non-product-version entity',
      ),
      metrics: [],
    };
  const resourcesReferences = getEntityRelations(entity, RELATION_DEPENDS_ON, {
    kind: 'resource',
  });
  if (resourcesReferences.length === 0)
    return {
      error: new Error(
        `No resources found for the entity ${entity.metadata.name}`,
      ),
      metrics: [],
    };
  const metric = createMetrics(
    resourcesReferences.length,
    'Resources',
    DatabaseIcon,
  );
  return { metrics: [metric] };
};

const getLinkMetrics: MetricsMapFunction = (
  entity,
  _relatedEntities,
  _getEntityRoute,
) => {
  if (!entity.metadata.links || entity.metadata.links.length === 0)
    return { metrics: [] };
  const metrics = entity.metadata.links.map(link => {
    return createMetrics(
      link.title?.toString() ?? link.url,
      'Link',
      OpenInNew,
      link.url,
    );
  });

  return { metrics };
};

const getMetricsMap = {
  owner: getOwnerMetrics,
  namespace: getNamespaceMetrics,
  package: getPackageMetrics,
  parentProduct: getParentProductMetrics,
  products: getProductsMetrics,
  components: getComponentsMetrics,
  resources: getResourcesMetrics,
  link: getLinkMetrics,
} as const satisfies {
  [key: string]: MetricsMapFunction;
};

const getMetrics = (
  metricsKey: MetricsKey,
  entity: Entity,
  relatedEntities: Entity[] | undefined,
  getEntityRoute: (entity: Entity) => string | null,
) => {
  if (!relatedEntities)
    return { metrics: [], error: new Error('No related entities found') };
  return getMetricsMap[metricsKey](entity, relatedEntities, getEntityRoute);
};

/**
 * Hook to get metrics for an entity
 * @param entity The entity to get metrics for
 * @param metricsKeysToDisplay The metrics keys to display
 * @returns { loading: boolean, metrics: Metric[], error?: string } The loading state, metrics and error message
 */
export const useMetrics: UseMetricsType = (
  entity,
  metricsKeysToDisplay = ['owner', 'namespace'],
) => {
  const generateEntityRoute = useRouteRef(entityRouteRef);
  const getEntityRoute = getEntityRouteFactory(generateEntityRoute);
  const { loading, entities, error } = useRelatedEntities(entity, {});

  const delayedLoading = useDelayedLoading(loading);

  if (delayedLoading || loading) return { loading: true, metrics: [] };

  let metricsToDisplay: Metric[] = [];

  if (error)
    return {
      loading: false,
      metrics: [],
      error: new Error(`Failed to load metrics: ${error}`),
    };

  try {
    metricsToDisplay = metricsKeysToDisplay.flatMap(key => {
      const { metrics, error: err } = getMetrics(
        key,
        entity,
        entities,
        getEntityRoute,
      );

      if (err) throw err;
      return metrics;
    });
  } catch (err: any) {
    return {
      loading: false,
      metrics: [],
      error: new Error(`Failed to load metrics: ${err.message}`),
    };
  }

  return { loading: false, metrics: metricsToDisplay };
};
