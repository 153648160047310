import React from 'react';
import { type GridRenderCellParams } from '@mui/x-data-grid';
import { IconComponent } from '@backstage/core-plugin-api';
import { makeStyles } from '@material-ui/core';

type IconTextProps = {
  Icon: IconComponent | undefined;
  text: string;
};

const useIconTextStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.text.primary,
    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

export const IconText = (props: IconTextProps) => {
  const { Icon, text } = props;
  const classes = useIconTextStyles();

  return (
    <div className={classes.root}>
      {Icon && <Icon fontSize="small" />}
      <span>{text}</span>
    </div>
  );
};

export const renderCellIconText =
  (iconMap: Record<string, IconComponent | undefined>) =>
  (params: GridRenderCellParams<any, string>) => {
    if (!params.value) {
      return null;
    }
    return <IconText Icon={iconMap[params.value]} text={params.value} />;
  };
