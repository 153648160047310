import {
  Button,
  ClickAwayListener,
  makeStyles,
  Paper,
  Popper,
} from '@material-ui/core';
import React from 'react';
import { DeployedCodeUpdateIcon } from 'backstage-plugin-icons-react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { SOURCE_CODE_URL_ANNOTATION } from 'custom-annotations';
import { useProductVersions } from '../../../useProductVersions';
import { AvailableVersionItem, DeployedVersionItem } from './VersionItems';
import {
  DisabledDeployTooltip,
  DisabledDeployTooltipText,
} from './DisabledDeployTooltip';
import { DeployProductButtonSection } from './DeployProductButtonSection';
import { Entity } from '@backstage/catalog-model';
import { useDeployedVersions } from './useDeployedVersions';
import partition from 'lodash.partition';
import { classNames } from '../../../../../../../../../utils/classNames';
import { isProductReady } from '../../../utils';

// TODO: find a better way to manage projects env
const DEV_PROJECTS = new Set(['adeo-dev-project']);

const useDeployButtonStyles = makeStyles(theme => ({
  popper: {
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    maxHeight: '350px',
    borderRadius: '4px',
    boxShadow: theme.shadows[1],
  },
  paper: {
    boxShadow: 'none',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  versionsList: {
    padding: 0,
    margin: 0,
  },
  deployedVersionsList: {
    marginBottom: theme.spacing(4),
  },
}));

interface DeployButtonProps {
  disableDeploy: boolean;
  versionEntities?: Entity[];
}

const DeployButton = ({
  disableDeploy,
  versionEntities,
}: DeployButtonProps) => {
  const classes = useDeployButtonStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>();

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'deploy-popper' : undefined;

  const deployedVersions = useDeployedVersions(versionEntities);

  const [versionsOnDev, versionsNotOnDev] = partition(
    deployedVersions,
    version => version.deployments.some(dep => DEV_PROJECTS.has(dep)),
  );

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<DeployedCodeUpdateIcon />}
        onClick={onClick}
        disabled={disableDeploy}
        aria-describedby={id}
      >
        Deploy
      </Button>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={classes.popper}
        placement="bottom-end"
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <Paper className={classes.paper}>
            <DeployProductButtonSection title="Deployed in development">
              {!!deployedVersions?.length && (
                <ul
                  className={classNames(
                    classes.versionsList,
                    classes.deployedVersionsList,
                  )}
                >
                  {versionsOnDev.map(version => (
                    <DeployedVersionItem
                      version={version}
                      key={version.entityRef}
                    />
                  ))}
                </ul>
              )}
            </DeployProductButtonSection>
            <DeployProductButtonSection title="Available to deployment in development">
              <ul className={classes.versionsList}>
                {versionsNotOnDev.map(version => (
                  <AvailableVersionItem
                    version={version}
                    key={version.entityRef}
                  />
                ))}
              </ul>
            </DeployProductButtonSection>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export const DeployProductButton = () => {
  const { entity } = useEntity();
  const manifestUrl = entity.metadata.annotations?.[
    SOURCE_CODE_URL_ANNOTATION
  ]?.replace('url:', '');

  const { entities, loading, error } = useProductVersions(entity);

  const disableDeploy =
    !isProductReady(entity) || loading || !!error || !entities?.length;

  return disableDeploy ? (
    <DisabledDeployTooltip
      title={<DisabledDeployTooltipText manifestUrl={manifestUrl} />}
      interactive
      arrow
    >
      <div>
        <DeployButton
          disableDeploy={disableDeploy}
          versionEntities={entities}
        />
      </div>
    </DisabledDeployTooltip>
  ) : (
    <DeployButton disableDeploy={disableDeploy} versionEntities={entities} />
  );
};
