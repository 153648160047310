import React, { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import {
  Container,
  debounce,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { Header } from '../header/Header';
import { HomeIcon, Inventory2SearchIcon } from 'backstage-plugin-icons-react';

import { PackageDiscoveryCard } from './PackageDiscoveryCard';
import {
  Breadcrumbs,
  BreadcrumbsItems,
} from '@internal/backstage-plugin-adeo-core-components-react';

const usePackageDiscoveryStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(2),
    height: '100%',
    marginTop: theme.spacing(5),
  },
  entityPresentation: {
    position: 'relative',
  },
  actionButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  searchIcon: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
}));

export const PackageDiscovery = () => {
  const classes = usePackageDiscoveryStyles();
  const catalogApi = useApi(catalogApiRef);
  const [packages, setPackages] = useState<Entity[]>([]);
  const [titleFilter, setTitleFilter] = useState<string>('');

  const onTitleFilterChange = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitleFilter(e.target.value);
    },
    300,
  );

  useEffect(() => {
    catalogApi
      .getEntities({
        filter: { kind: 'Component', 'spec.type': 'package' },
        order: { field: 'metadata.title', order: 'asc' },
      })
      .then(response => {
        setPackages(
          response.items.filter(packageEntity =>
            packageEntity.metadata.title
              ?.toLowerCase()
              .includes(titleFilter.toLowerCase()),
          ),
        );
      });
  }, [catalogApi, titleFilter]);

  return (
    <>
      <Header withBottomBorder>
        <Breadcrumbs>
          <BreadcrumbsItems label="Home" to="/" Icon={HomeIcon} isCompact />
          <BreadcrumbsItems
            label="Package discovery"
            Icon={Inventory2SearchIcon}
            isLast
          />
        </Breadcrumbs>
      </Header>

      <Container maxWidth="lg" className={classes.root}>
        <TextField
          variant="outlined"
          placeholder="Search a package"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
          onChange={onTitleFilterChange}
        />
        <Grid container spacing={2}>
          {packages.map(packageEntity => (
            <PackageDiscoveryCard
              packageEntity={packageEntity}
              key={`${packageEntity.metadata.namespace}-${packageEntity.metadata.name}`}
            />
          ))}
        </Grid>
      </Container>
    </>
  );
};
