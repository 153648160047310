import { EntityLayout } from '@backstage/plugin-catalog';
import React from 'react';
import { Grid } from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';
import { AboutSubscription } from './overview/AboutSubscription';
import { SubscriptionStatus } from './overview/SubscriptionStatus';
import { SubscriptionRoutes } from './overview/SubscriptionRoutes';

export const SubscriptionEntityPage = () => {
  const { entity } = useEntity();

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item sm={6}>
            <AboutSubscription entity={entity} />
          </Grid>
          <Grid item sm={6}>
            <SubscriptionStatus entity={entity} />
          </Grid>
          <Grid item sm={6}>
            <SubscriptionRoutes links={entity.metadata.links ?? []} />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );
};
