import React, { forwardRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Chip, { type ChipProps } from '@material-ui/core/Chip';

import { EntityPresentationContext } from '../EntityPresentationContext';
import { IconComponent } from '@backstage/core-plugin-api';
import { ChipLink } from '../../ChipLink';

/**
 * A tag to be displayed in the header of an entity presentation.
 *
 * @public
 */
export type Tag = {
  icon?: IconComponent;
  label: string;
  variant?: ChipProps['variant'];
  color?: ChipProps['color'];
  link?: string | null;
};

/**
 * Props for {@link EntityPresentationHeader}.
 *
 * @public
 */
export type EntityPresentationHeaderProps = {
  title: string;
  type: string;
  subtitle?: string;
  tags?: Tag[];
} & React.HTMLAttributes<HTMLDivElement>;

const useEntityPresentationHeaderStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    margin: theme.spacing(2),
  },

  head: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    gap: theme.spacing(0),
  },

  headline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: theme.spacing(1),
  },

  tags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(0),
  },
}));

type TagChipProps = {
  tag: Tag;
  size: 'small' | 'medium';
};

const TagChip = (props: TagChipProps) => {
  const { tag, size } = props;
  const iconComponent = tag.icon ? <tag.icon /> : undefined;
  const ChipComponent = tag.link ? ChipLink : Chip;
  const labelLength = tag.label.length;

  if (labelLength > 40) {
    const displayedLabel = `${tag.label.substring(0, 27)}...${tag.label.substring(labelLength - 10, labelLength)}`;
    return (
      <Tooltip arrow title={tag.label}>
        <ChipComponent
          icon={iconComponent}
          label={displayedLabel}
          variant={tag.variant}
          color={tag.color}
          to={tag.link ?? ''}
          size={size}
        />
      </Tooltip>
    );
  }

  return (
    <ChipComponent
      icon={iconComponent}
      label={tag.label}
      variant={tag.variant}
      color={tag.color}
      to={tag.link ?? ''}
      size={size}
    />
  );
};

/**
 * Displays a header for an entity presentation.
 *
 * @remarks
 *
 * This component displays a header for an entity presentation, including a title, type, subtitle,
 * and tags. This component is intended to be used within an {@link EntityPresentation}.
 *
 * @public
 */
export const EntityPresentationHeader = forwardRef<
  HTMLDivElement,
  EntityPresentationHeaderProps
>((props, ref) => {
  const { size } = useContext(EntityPresentationContext);
  const classes = useEntityPresentationHeaderStyles();
  const { title, type, subtitle, tags, ...restProps } = props;
  return (
    <div ref={ref} className={classes.root} {...restProps}>
      <div className={classes.head}>
        <Typography variant="overline">{type}</Typography>
        <div className={classes.headline}>
          <Typography variant={size === 'small' ? 'h5' : 'h3'}>
            {title}
          </Typography>
          <Typography variant={size === 'small' ? 'body2' : 'body1'}>
            {subtitle}
          </Typography>
        </div>
      </div>

      <div className={classes.tags}>
        {tags?.map(tag => <TagChip key={tag.label} tag={tag} size={size} />)}
      </div>
    </div>
  );
});

EntityPresentationHeader.displayName = 'EntityPresentationHeader';
