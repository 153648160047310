import React from 'react';
import { GitBranchIcon, GitCommitIcon } from 'backstage-plugin-icons-react';
import { makeStyles } from '@material-ui/core';

const useDeploymentAccordionGitInfoStyles = makeStyles(theme => ({
  root: {
    flex: 1.3,
    overflow: 'hidden',
  },
  commitText: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icons: {
    width: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  gitText: {
    fontFamily: 'monospace',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: theme.spacing(0.5),
  },
}));

interface DeploymentAccordionGitInfoProps {
  branch?: string;
  commitId?: string;
  commitMessage?: string;
}

export const DeploymentAccordionGitInfo = ({
  branch,
  commitId,
  commitMessage,
}: DeploymentAccordionGitInfoProps) => {
  const classes = useDeploymentAccordionGitInfoStyles();

  return (
    <div className={classes.root}>
      <div className={classes.flexCenter}>
        <GitBranchIcon className={classes.icons} />
        <span className={classes.gitText}>{branch}</span>
      </div>
      <div className={classes.flexCenter}>
        <GitCommitIcon className={classes.icons} />
        <span className={classes.commitText}>
          <span className={classes.gitText}>{commitId}</span>
          <span>{commitMessage}</span>
        </span>
      </div>
    </div>
  );
};
