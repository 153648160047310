import {
  Entity,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { useEffect, useState } from 'react';

export type SubscriptionWithVersion = Entity & { version?: Entity };

export const useProductSubscriptions = (
  product: Entity,
): SubscriptionWithVersion[] => {
  const catalogApi = useApi(catalogApiRef);
  const isProductRef = product.spec?.type === 'product';

  const [subscriptionsWithVersion, setSubscriptionsWithVersion] = useState<
    SubscriptionWithVersion[]
  >([]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      // Get a list of entities that this product is a dependency of (should include subscriptions)
      const dependencyOfRelationsRefs = getEntityRelations(
        product,
        RELATION_DEPENDENCY_OF,
      );

      // Get a list of entities that this product depends on (should include product versions)
      // If entity is a version and not a ref, skip it
      const dependsOnRelationsRefs = isProductRef
        ? getEntityRelations(product, RELATION_DEPENDS_ON)
        : [];

      const { items } = await catalogApi.getEntitiesByRefs({
        entityRefs: [
          ...dependencyOfRelationsRefs,
          ...dependsOnRelationsRefs,
        ].map(stringifyEntityRef),
      });

      // Filter out any entities that are not subscriptions or product versions
      const [subscriptions, versions] = items.reduce(
        (acc: [Entity[], Entity[]], entity) => {
          if (!entity) return acc;

          const [subscriptionsEntities, versionEntities] = acc;

          if (entity.spec?.type === 'subscription') {
            return [[...subscriptionsEntities, entity], versionEntities];
          } else if (entity.spec?.type === 'productVersion') {
            return [subscriptionsEntities, [...versionEntities, entity]];
          }

          return acc;
        },
        isProductRef ? [[], []] : [[], [product]],
      );

      // Map each subscription to include the version it depends on
      setSubscriptionsWithVersion(
        subscriptions.map(subscription => ({
          ...subscription,
          version: versions.find(version =>
            getEntityRelations(version, RELATION_DEPENDENCY_OF)
              .map(stringifyEntityRef)
              .includes(stringifyEntityRef(subscription)),
          ),
        })),
      );
    };

    fetchSubscriptions();
  }, [product, catalogApi, isProductRef]);

  return subscriptionsWithVersion;
};
