import React from 'react';
import { ContentSkeleton } from '../ProductRefEntitySkeletons';
import { makeStyles, Typography } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/Inbox';
import { useEntity } from '@backstage/plugin-catalog-react';
import { isEntityInitializedOrBeyond } from '../../../../../../../../utils/lifecycleUtils';

const useProductReleaseCardContentStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  inboxIcon: {
    fill: theme.palette.text.disabled,
  },
  emptyContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  emptyContentText: {
    color: theme.palette.text.disabled,
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
}));

export const ProductReleaseCardContent = () => {
  const { entity } = useEntity();
  const initialized = isEntityInitializedOrBeyond(entity);
  const classes = useProductReleaseCardContentStyles();

  return (
    <div className={classes.root}>
      {initialized ? (
        <div className={classes.emptyContent}>
          <InboxIcon className={classes.inboxIcon} fontSize="large" />
          <Typography variant="overline" className={classes.emptyContentText}>
            No release or feature branch available at this time. As soon as you
            create one, it will appear here
          </Typography>
        </div>
      ) : (
        <ContentSkeleton />
      )}
    </div>
  );
};
