import { Page, Content, InfoCard } from '@backstage/core-components';
import {
  HomePageCompanyLogo,
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageRecentlyVisited,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { shortcuts } from './shortcuts';
import Logo from '../Root/Logo';
import './HomePage.css';

const useStyles = makeStyles(theme => ({
  logoContainer: {
    margin: theme.spacing(5, 0),
  },
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  infoCard: {
    height: '100%',
  },
}));
export const HomePage = () => {
  const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={2}>
            <HomePageCompanyLogo
              className={classes.logoContainer}
              logo={<Logo isTextDisplayed isThemeAdaptative height="5rem" />}
            />
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} md={5}>
                <InfoCard title="Welcome to AAP" className={classes.infoCard}>
                  Adeo Application Platform is the one stop shop to find an
                  application, install it easily and help you monitor it in your
                  daily tasks.
                </InfoCard>
              </Grid>
              <Grid item xs={12} md={7} className="fullHeightCard">
                <HomePageToolkit tools={shortcuts} />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6} className="fullHeightCard">
                <HomePageRecentlyVisited />
              </Grid>
              <Grid item xs={12} md={6} className="fullHeightCard">
                <HomePageStarredEntities />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
