import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { HealthStatusFilter } from './HealthStatusFilter';
import { SyncStatusFilter } from './SyncStatusFilter';
import { ProjectsFilter } from './ProjectsFilter';

const useProductRefDeploymentFiltersStyles = makeStyles(theme => ({
  searchIcon: {
    color: theme.palette.grey['400'],
  },
  filtersContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(1),
  },
  searchInput: {
    flex: 2.35,
    '& > [class^="MuiInputBase-root"]': {
      background: theme.palette.common.white,
    },
  },
}));

interface ProductRefDeploymentFiltersProps {
  onSearch: (query: string) => void;
}

export const ProductDeploymentFilters = ({
  onSearch,
}: ProductRefDeploymentFiltersProps) => {
  const classes = useProductRefDeploymentFiltersStyles();

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <div className={classes.filtersContainer}>
      <TextField
        variant="outlined"
        placeholder="Search a version"
        className={classes.searchInput}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          ),
        }}
        onChange={onSearchChange}
      />
      <ProjectsFilter />
      <HealthStatusFilter />
      <SyncStatusFilter />
    </div>
  );
};
