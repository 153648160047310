import React, { useMemo } from 'react';
import { type Entity } from '@backstage/catalog-model';

import {
  EntityPresentation,
  EntityPresentationDescription,
  EntityPresentationHeader,
  type Tag,
} from '@internal/backstage-plugin-adeo-core-components-react';

import { Card, makeStyles } from '@material-ui/core';
import { GithubIcon } from 'backstage-plugin-icons-react';

export type ComponentAndResourceCardProps = {
  entity: Entity;
};

const useComponentAndResourceCardStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  errorContainer: {
    margin: theme.spacing(2),
  },
}));

export const ComponentAndResourceCard = (
  props: ComponentAndResourceCardProps,
) => {
  const { entity } = props;

  const classes = useComponentAndResourceCardStyles();

  const type = useMemo(
    () => `${entity.kind} - ${entity.spec?.type?.toString() ?? ''}`,
    [entity],
  );

  const tags = useMemo(() => {
    const sourceLocation =
      entity.metadata?.annotations?.['backstage.io/source-location'].replace(
        'url:',
        '',
      ) ?? '';

    return [
      {
        icon: GithubIcon,
        label: sourceLocation,
        variant: 'outlined',
        link: sourceLocation,
      },
    ] satisfies Tag[];
  }, [entity]);

  return (
    <Card>
      <EntityPresentation size="small">
        <div className={classes.content}>
          <EntityPresentationHeader
            title={entity.metadata.title ?? 'No title'}
            type={type}
            tags={tags}
          />
          <EntityPresentationDescription
            description={entity.metadata.description ?? 'No description'}
          />
        </div>
      </EntityPresentation>
    </Card>
  );
};

ComponentAndResourceCard.displayName = 'ComponentAndResourceCard';
