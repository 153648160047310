import React from 'react';
import { Entity, RELATION_DEPENDS_ON } from '@backstage/catalog-model';
import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  renderCellIconText,
  Table,
  ButtonCard,
  EntityPresentation,
  EntityPresentationHeader,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { DatabaseIcon, DeployedCodeIcon } from 'backstage-plugin-icons-react';
import { useDelayedLoading } from '../../../../../../../hooks/useDelayedLoading';
import { useEntityRoute } from '../../../../../../../hooks/useEntityRoute';

type ProductVersionListItemProps = {
  entity: Entity;
};

const useProductVersionsListItemStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  emptyContent: {
    display: 'flex',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
  content: {
    padding: theme.spacing(2),
  },
}));

const iconTypeMap = {
  api: DeployedCodeIcon,
  postgresql: DatabaseIcon,
} as const;

export const ProductVersionsListItem = (props: ProductVersionListItemProps) => {
  const { entity } = props;
  const classes = useProductVersionsListItemStyles();
  const entityRoute = useEntityRoute(entity);

  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_DEPENDS_ON,
  });

  const delayedLoading = useDelayedLoading(loading);

  return (
    <ButtonCard to={entityRoute}>
      <div className={classes.header}>
        <EntityPresentation size="small">
          <EntityPresentationHeader
            title={entity.metadata.title ?? ''}
            type="product version"
          />
        </EntityPresentation>
      </div>

      <div className={classes.emptyContent}>
        {error && <Alert severity="error">{error.message}</Alert>}
      </div>

      <div className={classes.content}>
        {delayedLoading ? (
          <Skeleton variant="rect" height={140} data-testid="skeleton" />
        ) : (
          <Table
            columns={[
              {
                field: 'type',
                headerName: 'Type',
                flex: 0.5,
                renderCell: renderCellIconText(iconTypeMap),
              },
              { field: 'title', headerName: 'Name', flex: 1 },
              { field: 'description', headerName: 'Description', flex: 2 },
            ]}
            rows={
              entities?.map(dependencies => ({
                title: dependencies.metadata.title,
                description: dependencies.metadata.description,
                type: dependencies.spec?.type?.toString(),
                id: dependencies.metadata.uid,
              })) ?? []
            }
            disableColumnSelector
            disableRowSelectionOnClick
          />
        )}
      </div>
    </ButtonCard>
  );
};

ProductVersionsListItem.displayName = 'ProductVersionsListItem';
