import { ButtonCard } from '@internal/backstage-plugin-adeo-core-components-react';
import React, { PropsWithChildren } from 'react';
import { Grid, styled } from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';
import { SOURCE_CODE_URL_ANNOTATION } from 'custom-annotations';
import { isEntityInitializedOrBeyond } from '../../../../../../../../utils/lifecycleUtils';

const Backdrop = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backdropFilter: 'blur(6px)',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ProductReleaseCard = ({ children }: PropsWithChildren<{}>) => {
  const { entity } = useEntity();
  const initialized = isEntityInitializedOrBeyond(entity);
  const repoUrl = entity?.metadata.annotations?.[
    SOURCE_CODE_URL_ANNOTATION
  ]?.replace('url:', '');

  return (
    <Grid lg={6} xs={12} item>
      <ButtonCard style={{ position: 'relative' }} to={repoUrl}>
        {!initialized && <Backdrop data-testid="backdrop" />}
        {children}
      </ButtonCard>
    </Grid>
  );
};
