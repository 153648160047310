import { EntityAboutCard, EntityLayout } from '@backstage/plugin-catalog';
import { Grid } from '@material-ui/core';
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import { RelationsContent } from '../../content/RelationsContent';
import React from 'react';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntitySonarQubeCard } from '@backstage-community/plugin-sonarqube';
import { EntityWarningContent } from '../../content/EntityWarningContent';
import { TechDocsContent } from '../../content/TechDocsContent';
import { CicdContent } from '../../content/CicdContent';
import { PullRequestsContent } from '../../content/PullRequestContent';
import { LinksCard } from '../../../links/LinksCard';

const apiEntityOverview = (
  <Grid container spacing={3} alignItems="stretch">
    {EntityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard
        variant="gridItem"
        height={400}
        kinds={['API', 'Component']}
      />
    </Grid>
    <Grid item md={4} xs={12}>
      <LinksCard types={['link']} cols={1} />
    </Grid>
    <Grid item md={4} xs={12}>
      <LinksCard types={['route']} title="Routes" cols={1} />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntitySonarQubeCard variant="gridItem" />
    </Grid>
  </Grid>
);

export const ApiEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {apiEntityOverview}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {TechDocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item sm={12}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item sm={12}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull requests">
      {PullRequestsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/github-insights" title="Github insights">
      {CicdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/relations" title="Relations">
      {RelationsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
