import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const HEALTH_STATUSES = [
  'Healthy',
  'Progressing',
  'Suspended',
  'Degraded',
  'Missing',
  'Unknown',
];
const SYNC_STATUSES = [
  'Synced',
  'Syncing',
  'OutOfSync',
  'SyncFailed',
  'Unknown',
];

interface DeploymentsStore {
  // Status
  healthStatus: string[];
  healthStatusFilter: string[];
  setHealthStatusesFilter: (statuses: string[]) => void;
  syncStatus: string[];
  syncStatusFilter: string[];
  setSyncStatusesFilter: (statuses: string[]) => void;
  // Projects
  projects: string[];
  projectFilter: string[];
  setProjects: (projects: string[]) => void;
  setProjectFilter: (projects: string[]) => void;
  // Common
  resetFilters: () => void;
}

export const useDeploymentsStore = create<DeploymentsStore>()(
  devtools(set => ({
    // Status
    healthStatus: HEALTH_STATUSES,
    healthStatusFilter: HEALTH_STATUSES,
    syncStatus: SYNC_STATUSES,
    syncStatusFilter: SYNC_STATUSES,
    setHealthStatusesFilter: (statuses: string[]) =>
      set(() => ({ healthStatusFilter: statuses })),
    setSyncStatusesFilter: (statuses: string[]) =>
      set(() => ({ syncStatusFilter: statuses })),
    // Projects
    projects: [],
    projectFilter: [],
    setProjects: (projects: string[]) =>
      set(() => {
        const uniqueProjects = Array.from(new Set(projects));

        return { projects: uniqueProjects, projectFilter: uniqueProjects };
      }),
    setProjectFilter: (projects: string[]) =>
      set(() => ({ projectFilter: projects })),
    // Common
    resetFilters: () =>
      set(() => ({
        syncStatusFilter: [],
        healthStatusFilter: [],
        projectFilter: [],
      })),
  })),
);
