import React, { forwardRef } from 'react';
import { Card, IconButton, makeStyles } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { useNavigate } from 'react-router-dom';

/**
 * Props for {@link ButtonCard}.
 *
 * @public
 */
export type ButtonCardProps = {
  to?: string;
  button?: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLDivElement>;

const useButtonCardStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    padding: 0,
    border: 'none',
    background: 'none',
    textAlign: 'left',
    height: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  actionButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  ArrowIcon: {
    alignSelf: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '50%',
  },
}));

/**
 * A button that wraps a card.
 *
 * @remarks
 *
 * This component forwards all props to the underlying button element.
 *
 * @public
 */
export const ButtonCard = forwardRef<HTMLDivElement, ButtonCardProps>(
  (props, ref) => {
    const { children, className, to, button, ...restProps } = props;
    const classes = useButtonCardStyles();
    const navigate = useNavigate();

    const buttonClass = `${classes.root} ${className ?? ''}`;

    const handleClick = () => {
      if (!to) return;

      if (to.startsWith('https')) {
        window.open(to, '_blank', 'noopener,noreferrer')?.focus();
      } else {
        navigate(to);
      }
    };

    return (
      <div ref={ref} className={buttonClass} {...restProps}>
        <Card className={classes.card}>
          <div className={classes.actionButton}>
            {button ? (
              React.cloneElement(button as React.ReactElement, {
                onClick: handleClick,
              })
            ) : (
              <IconButton className={classes.ArrowIcon} onClick={handleClick}>
                <ArrowForward />
              </IconButton>
            )}
          </div>
          {children}
        </Card>
      </div>
    );
  },
);

ButtonCard.displayName = 'ButtonCard';
