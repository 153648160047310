import React, { useState } from 'react';
import { Entity } from '@backstage/catalog-model';
import {
  InspectEntityDialog,
  UnregisterEntityDialog,
  useEntity,
} from '@backstage/plugin-catalog-react';

import { EntityContextMenu } from './EntityContextMenu';
import { ProductVersionEntityBreadcrumb } from './entity-breadcrumbs/ProductVersionEntityBreadcrumb';
import { ProductRefEntityBreadcrumb } from './entity-breadcrumbs/ProductRefEntityBreadcrumb';
import { PackageEntityBreadcrumb } from './entity-breadcrumbs/PackageEntityBreadcrumb';
import { GroupEntityBreadcrumb } from './entity-breadcrumbs/GroupEntityBreadcrumb';
import { UserEntityBreadcrumb } from './entity-breadcrumbs/UserEntityBreadcrumb';
import { Header } from '../../header/Header';

const breadcrumbMap: Record<string, React.ReactNode> = {
  'Component:package': <PackageEntityBreadcrumb />,
  'Component:product': <ProductRefEntityBreadcrumb />,
  'Component:productVersion': <ProductVersionEntityBreadcrumb />,
  Group: <GroupEntityBreadcrumb />,
  User: <UserEntityBreadcrumb />,
};

const getBreadcrumb = (entity: Entity): React.ReactNode => {
  const key =
    entity.kind === 'Component'
      ? `${entity.kind}:${entity.spec?.type}`
      : entity.kind;
  return breadcrumbMap[key] ?? null;
};

const EntityLayoutHeader = () => {
  const { entity } = useEntity();
  const [inspectionDialogOpen, setInspectionDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const breadcrumb = getBreadcrumb(entity);

  const cleanUpAfterRemoval = async () => {
    setConfirmationDialogOpen(false);
    setInspectionDialogOpen(false);
  };

  return (
    <Header>
      {breadcrumb}
      <EntityContextMenu
        onInspectEntity={() => setInspectionDialogOpen(true)}
        onUnregisterEntity={() => setConfirmationDialogOpen(true)}
      />

      <UnregisterEntityDialog
        open={confirmationDialogOpen}
        entity={entity}
        onConfirm={cleanUpAfterRemoval}
        onClose={() => setConfirmationDialogOpen(false)}
      />
      <InspectEntityDialog
        open={inspectionDialogOpen}
        entity={entity}
        onClose={() => setInspectionDialogOpen(false)}
      />
    </Header>
  );
};

EntityLayoutHeader.displayName = 'EntityLayoutHeader';

export default EntityLayoutHeader;
