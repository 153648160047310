import React from 'react';
import { Container, Divider, makeStyles } from '@material-ui/core';
import {
  Entity,
  type GroupEntity,
  RELATION_OWNED_BY,
} from '@backstage/catalog-model';
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import {
  type Tag,
  EntityPresentation,
  EntityPresentationHeader,
  EntityPresentationDescription,
  EntityPresentationMetrics,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { Alert } from '@material-ui/lab';
import { ProductRefEntityUsers } from './ProductRefEntityUsers';
import {
  getLifecycleTag,
  LifecycleStatus,
} from '../../../../../../../utils/getLifecycleTag';
import { useMetrics } from '../../../../../../../hooks/useMetrics';
import { ProductReleaseCard } from './product-release-card/ProductReleaseCard';
import { ProductReleaseCardHeader } from './product-release-card/ProductReleaseCardHeader';
import { ProductReleaseCardContent } from './product-release-card/ProductReleaseCardContent';
import { InitializeManifestButton } from './action-buttons/InitializeManifestButton';
import { DeployProductButton } from './action-buttons/DeployProductButton/DeployProductButton';
import { ProductVersionsList } from './ProductVersionsList';
import {
  isEntityDeclared,
  isEntityInitializedOrBeyond,
} from '../../../../../../../utils/lifecycleUtils';

const useProductRefEntityRefStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(2),
  },
  entityPresentation: {
    position: 'relative',
  },
  actionButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  errorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

interface ActionButtonProps {
  entity: Entity;
}

const ActionButton = ({ entity }: ActionButtonProps) => {
  if (isEntityDeclared(entity)) {
    return <InitializeManifestButton />;
  } else if (isEntityInitializedOrBeyond(entity)) {
    return <DeployProductButton />;
  }
  return null;
};

export const ProductRefEntityOverview = () => {
  const classes = useProductRefEntityRefStyles();
  const { entity } = useEntity();

  const { loading: loadingOwnedBy, entities: ownerGroups } = useRelatedEntities(
    entity,
    {
      kind: 'group',
      type: RELATION_OWNED_BY,
    },
  ) as {
    loading: boolean;
    entities: GroupEntity[] | undefined;
    error?: Error;
  };

  const {
    loading: loadingMetrics,
    metrics,
    error,
  } = useMetrics(entity, ['package', 'owner', 'link']);

  const type = 'Product';

  const lifecycle =
    (entity.spec?.lifecycle?.toString() as LifecycleStatus) ?? '';
  const tags: Tag[] = [getLifecycleTag(lifecycle)];

  return (
    <Container maxWidth="lg" className={classes.root}>
      <EntityPresentation size="medium" className={classes.entityPresentation}>
        <div className={classes.actionButton}>
          <ActionButton entity={entity} />
        </div>
        <EntityPresentationHeader
          title={entity.metadata.title ?? ''}
          type={type}
          tags={tags}
        />
        <EntityPresentationDescription
          description={entity.metadata.description ?? ''}
        />
        {ownerGroups?.[0] && (
          <ProductRefEntityUsers
            ownerGroup={ownerGroups[0]}
            loading={loadingOwnedBy}
          />
        )}
        <Divider />
        {error ? (
          <Alert severity="error" className={classes.errorContainer}>
            {error.message}
          </Alert>
        ) : (
          <EntityPresentationMetrics
            metrics={metrics}
            loading={loadingMetrics}
          />
        )}
        <Divider />
      </EntityPresentation>
      <ProductReleaseCard>
        <ProductReleaseCardHeader />
        <Divider />
        <ProductReleaseCardContent />
      </ProductReleaseCard>
      <ProductVersionsList entity={entity} />
    </Container>
  );
};
